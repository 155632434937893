/* stylelint-disable order/properties-order */
/* stylelint-disable prettier/prettier */
.comment-card-wrapper {
  position: relative;
  width: 300px;
  height: auto;
  margin: 1rem;
  overflow: hidden; /* Ensures nothing spills outside the boundary */
  border-radius: 0.5rem; /* Applies border-radius here to contain everything */
}

.gradient-border {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: calc(100% + 8px); /* Slightly less width and height to show the border */
  height: calc(100% + 8px);
  margin: 2px; /* Centers the border within the wrapper */
  background: linear-gradient(to right, #618AC8, #75C7CC);
  border-radius: 0.5rem;
}

.comment-card-content {
  position: relative;
  background: white;
  border-radius: 0.5rem;
  padding: 2rem;
  z-index: 10;
}

